<template>
  <div class="inline-flex items-center gap-2 mt-3 cursor-pointer" @click="goback">
    <img src="../assets/icons/arrow-left.svg" alt="" />
    <p class="text-[14px] text-light-gray">Go back</p>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const goback = () => {
  router.go(-1);
};
</script>
