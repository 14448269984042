<template>
  <div v-if="open" class="fixed inset-0 z-40">
    <!-- OVERLAY -->
    <div class="fixed inset-0 bg-gray-900 opacity-70 backdrop-blur-3xl"></div>
    <!-- FILTER -->
    <div class="fixed top-0 bottom-0 right-0 bg-white max-w-lg w-9/12 md:w-1/2 p-5 rounded-tl-lg rounded-bl-lg">
      <div class="flex justify-between items-center mb-5 pt-2">
        <h1 class="font-bold text-[22px] text-brand-black">Email a Candidate</h1>
      </div>

      <div class="overflow-y-auto h-[85vh] custom-scroll">
        <div class="p-4 flex items-center rounded-xl border bg-[#FEFAF1] border-[#DEDEDE] gap-4 mb-6">
          <img :src="talentDetails.avatar || talentDetails.candidate.avatar" alt="profileImg" class="h-[56px] w-[56px] rounded-full" />
          <div>
            <p class="text-[#111827] text-xl font-bold">{{ talentDetails.name || talentDetails.candidate.name }}</p>
            <small class="text-sm text-[#8F8F8F]">Direct Application</small>
          </div>
        </div>

        <div class="">
          <div class="pt-4 mb-6 border border-[#F0F0F0] rounded-lg">
            <div class="w-full border-b border-[#F0F0F0] px-6 pb-3">
              <div class="flex items-end space-x-3">
                <span>To</span>
                <input
                  type="email"
                  class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-sm grow"
                  placeholder="Type an email address..."
                  readonly
                  :value="talentDetails.email"
                />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
              <div class="flex items-end space-x-3">
                <span>Subject</span>
                <input
                  type="text"
                  class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0"
                  placeholder="Invitation to Job Application"
                  v-model="details.subject"
                />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0]">
              <QuillEditor v-model:content="details.content" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
            </div>
          </div>

          <div class="mb-6">
            <div class="flex justify-end space-x-4">
              <button @click="closeModal" class="bg-white p-3 text-brand-black rounded-lg border border-brand-black px-10 text-[14px]">Cancel</button>
              <button class="bg-brand-black p-3 text-white rounded-lg px-10 text-[14px]" @click="emailTalent">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { alert } from '@/utils/helper';
import { ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const email = ref('');
const details = ref({
  subject: '',
  content: `Hi Cecilia,

Thank you very much for applying for the Engineering Manager position at Pixel Office.

Please be informed that we have received your application. Our hiring team is currently reviewing all applications. If you are among qualified candidates, you will receive an email notifying you of the next step soon.

Thanks again for your interest in working at our company.

Best regards,

Pixel Office`,
});

const props = defineProps({
  open: { type: Boolean },
  talentDetails: { type: Object },
  from: { type: String, default: 'talent' },
});

const emit = defineEmits(['onClose']);

const closeModal = () => {
  emit('onClose');
};

const emailTalent = async () => {
  try {
    const data = {
      id: props.talentDetails.uuid,
      details: details.value,
    };
    const res = await store.dispatch('global/emailTalent', data);
    if (res.status === true) {
      alert(res.message, 'success');
      closeModal();
    }
  } catch (error) {
    alert(error.response.data.message, 'error');
  }
};
</script>
